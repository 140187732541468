import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Select, Store } from "@ngxs/store";
import { Observable, Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { formatWord, translateAndFormat } from "src/app/i18next";
import { formatDate } from "src/app/i18next/formatDate";
import { LanguageDictionaryService } from "src/app/services/language-dictionary/language-dictionary.service";
import { PageInformation } from "src/app/services/previous-page.service";
import { environment } from "src/environments/environment";
import { displayName } from "src/utils/displayName/displayName";
import { getZonarOtherUUID } from "src/utils/getZonarOtherUUID/getZonarOtherUUID";
import { isDefined } from "src/utils/isDefined/isDefined";
import { newDate } from "src/utils/newDate/newDate";
import { AppState } from "../../app.state";
import {
	ClosedDefectViewModel,
	OpenDefectTableViewModel,
	RepairViewModel,
} from "../../models/open-defect-table.models";
import {
	Defect,
	DefectList,
	InspectionAsset,
	InspectionDetailData,
	InspectionGet,
	LangDictGet,
	RepairResponse,
} from "../../models/openAPIAliases";
import { BooleanTransformService } from "../../services/boolean-transform.service";
import { PhotoViewModelService } from "../../services/photo-view-model.service";
import {
	AssetState,
	ClearAsset,
	GetAssetInspection,
	GetClosedAssetDefects,
	GetOpenAssetDefects,
	SetClosedWithComments,
	SetOpenWithComments,
} from "./state/asset-details.state";

export interface AssetDetails {
	assetDetailContent: string;
	assetDetailConversionPair?: string;
	assetDetailIcon?: string;
	assetDetailLabel: string;
}

export interface ClosedDefects {
	closedDefects: OpenDefectTableViewModel[];
	comment: string;
	configId: string;
	resolution: string;
}

@Component({
	selector: "app-asset-details",
	templateUrl: "./asset-details.component.html",
	styleUrls: ["./asset-details.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class AssetDetailsComponent implements OnInit, OnDestroy {
	// Store selects: company, last inspection on asset, asset open defects, asset closed defects
	@Select(AppState.getSelectedCompanyId) selectedCompanyId$: Observable<string>;
	@Select(AppState.selectLanguageDictionary) languageDictionary$: Observable<LangDictGet>;
	@Select(AppState.selectPageInformation) pageInformation$: Observable<PageInformation>;
	@Select(AssetState.getAllClosedAssetDefects) assetClosedDefects$: Observable<Defect[]>;
	@Select(AssetState.getAllOpenAssetDefects) assetOpenDefects$: Observable<Defect[]>;
	@Select(AssetState.getAssetLastInspection) assetInspection$: Observable<InspectionGet>;
	@Select(AssetState.getTotalClosedDefects) totalClosedDefects$: Observable<number>;
	@Select(AssetState.getTotalOpenDefects) totalOpenDefects$: Observable<number>;

	private onDestroy$ = new Subject<void>();
	public assetDetailsArray: AssetDetails[] = [];
	public assetId: string = null;
	public assetInspection: InspectionGet;
	public closedDefects: ClosedDefects = null;
	public closedTableReady = false;
	public companyId: string = null;
	public isInitiallyLoading = true;
	public openTableReady = false;
	public previousPageTitle: string = null;
	public previousPageUrl: string = null;
	public totalClosedDefects = 0;
	public totalOpenDefects = 0;

	public currentClosedDefectQuery = new URLSearchParams({
		page: "1",
		perPage: "5",
		statuses: "ignored,repaired",
	});
	public currentOpenDefectQuery = new URLSearchParams({
		page: "1",
		perPage: "5",
		statuses: "open,pending",
	});

	constructor(
		private booleanTransformService: BooleanTransformService,
		private languageDictionaryService: LanguageDictionaryService,
		private photoViewModelService: PhotoViewModelService,
		private route: ActivatedRoute,
		private router: Router,
		private store: Store,
	) {}

	ngOnInit() {
		// get the previous page title and url from the store
		this.pageInformation$.subscribe((pageInformation: PageInformation) => {
			this.previousPageTitle = pageInformation ? pageInformation.previousPageTitle : "";
			this.previousPageUrl = pageInformation ? pageInformation.previousPageUrl : "";
		});

		// get environment variables and company ID and dispatch actions for inspection and defects on asset
		this.selectedCompanyId$.pipe(filter(isDefined), takeUntil(this.onDestroy$)).subscribe(selectedCompanyId => {
			// get asset and company ID from route parameter
			this.assetId = this.route.snapshot.paramMap.get("asset-uuid");
			this.companyId = this.route.snapshot.paramMap.get("company-uuid");

			if (this.assetId && this.companyId === selectedCompanyId) {
				// Dispatch GetAssetInspection, GetOpenAssetDefects, and GetClosedAssetDefects actions
				this.store.dispatch([
					new GetAssetInspection(
						this.assetId,
						this.companyId,
						environment.environmentConstants.APP_ENDPOINT_EVIR,
					),
					new GetOpenAssetDefects(
						this.assetId,
						this.companyId,
						environment.environmentConstants.APP_ENDPOINT_EVIR,
						this.currentOpenDefectQuery,
					),
					new GetClosedAssetDefects(
						this.assetId,
						this.companyId,
						environment.environmentConstants.APP_ENDPOINT_EVIR,
						this.currentClosedDefectQuery,
					),
				]);

				this.getAssetDetails();
			} else {
				this.router.navigate([
					this.previousPageUrl.includes("company") ? "/inspection-list" : this.previousPageUrl,
				]);
			}
		});
	}

	/**
	 * Subscribe to all Asset information data such as assetInspection, assetOpenDefects, assetClosedDefects, totalOpenDefects
	 */
	private getAssetDetails() {
		this.languageDictionary$.pipe(filter(isDefined), takeUntil(this.onDestroy$)).subscribe(() => {
			// build the asset details array for user view once data comes in
			this.assetInspection$
				.pipe(filter(isDefined), takeUntil(this.onDestroy$))
				.subscribe((inspection: InspectionGet) => {
					this.assetInspection = inspection;
					this.buildAssetDetails(this.assetInspection);
				});

			// Build open defect table view
			this.assetOpenDefects$
				.pipe(filter(isDefined), takeUntil(this.onDestroy$))
				.subscribe((openDefects: Defect[]) => {
					const openViewModel = openDefects.length > 0 ? this.buildOpenPendingViewModel(openDefects) : [];
					this.setOpenTableReadyAndDispatch(openViewModel);
				});

			// Build closed defect table view
			this.assetClosedDefects$
				.pipe(filter(isDefined), takeUntil(this.onDestroy$))
				.subscribe((closedDefects: Defect[]) => {
					const closedViewModel =
						closedDefects.length > 0 ? this.buildClosedDefectViewModel(closedDefects) : [];
					this.setClosedTableReadyAndDispatch(closedViewModel);
				});

			// Get total open defects
			this.totalOpenDefects$
				.pipe(filter(isDefined), takeUntil(this.onDestroy$))
				.subscribe((amountOpenDefects: number) => {
					this.totalOpenDefects = amountOpenDefects;
				});

			// Get total closed defects
			this.totalClosedDefects$
				.pipe(filter(isDefined), takeUntil(this.onDestroy$))
				.subscribe((amountClosedDefects: number) => {
					this.totalClosedDefects = amountClosedDefects;
				});
		});
	}

	// mark table ready for view and dispatch either empty array or defects with comments to store
	public setOpenTableReadyAndDispatch(openDefects: OpenDefectTableViewModel[]): void {
		this.openTableReady = true;
		this.store.dispatch(new SetOpenWithComments(openDefects));
	}

	public setClosedTableReadyAndDispatch(closedDefects: ClosedDefectViewModel[]): void {
		this.closedTableReady = true;
		this.store.dispatch(new SetClosedWithComments(closedDefects));
	}

	// create an asset details array for the ngFor loop in the template
	public buildAssetDetails(inspection: InspectionGet): void {
		// find the asset that matches the component's asset ID since an inspection can have multiple assets
		const assetObject =
			inspection.inspectionAssets && inspection.inspectionAssets.length > 0
				? inspection.inspectionAssets.find(
						(asset: InspectionAsset) => asset.asset.assetId.toLowerCase() === this.assetId.toLowerCase(),
				  )
				: null;

		// if the inspection has a details array, assign to var
		const assetDataArray = inspection.inspectionDetail ? inspection.inspectionDetail.inspectionDetailData : null;

		// push the asset number and equipment type
		if (assetObject && assetObject.asset) {
			this.assetDetailsArray.push({
				assetDetailLabel: translateAndFormat("asset number", "uppercase"),
				assetDetailContent: assetObject.asset.assetName,
				assetDetailIcon: assetObject.asset.assetCategory === "user" ? "outlined_flag" : null,
			});
		}

		if (assetObject && assetObject.zoneLayoutName) {
			this.assetDetailsArray.push({
				assetDetailLabel: translateAndFormat("equipment type", "uppercase"),
				assetDetailContent: assetObject.zoneLayoutName
					? this.languageDictionaryService.getTranslations(assetObject.zoneLayoutName)
					: null,
			});
		}

		// push all contents of the inspection detail array except for fields that were not filled out
		if (assetDataArray) {
			assetDataArray.forEach((inspectionDetail: InspectionDetailData) => {
				if (inspectionDetail.inspectionDetailContent !== "") {
					/*For the conversion pair recived adding the metric and format the inconsistent order of values received to display*/
					if ((inspectionDetail?.inspectionDetailUnitConversionPair?.length ?? 0) > 0) {
						this.assetDetailsArray.push({
							assetDetailLabel: inspectionDetail.inspectionDetailDataName
								? formatWord(
										this.languageDictionaryService.getTranslations(
											inspectionDetail.inspectionDetailDataName,
										),
										"uppercase",
								  )
								: null,
							assetDetailContent: this.booleanTransformService.booleanTransform(
								inspectionDetail.inspectionDetailUnitConversionPair,
								inspectionDetail.inspectionDetailContent,
							),
							assetDetailConversionPair: this.booleanTransformService.booleanTransform(
								inspectionDetail.inspectionDetailUnitConversionPair,
							),
						});
					} else if (
						/*if the conversion pair is not recieved but only single pair of unit is received then display the value as is */
						inspectionDetail.inspectionDetailSelectedUnit
					) {
						this.assetDetailsArray.push({
							assetDetailLabel: inspectionDetail.inspectionDetailDataName
								? formatWord(
										this.languageDictionaryService.getTranslations(
											inspectionDetail.inspectionDetailDataName,
										),
										"uppercase",
								  )
								: null,
							assetDetailContent: this.booleanTransformService.booleanTransform(
								inspectionDetail.inspectionDetailSelectedUnit,
								inspectionDetail.inspectionDetailContent,
							),

							assetDetailConversionPair: this.booleanTransformService.booleanTransform(
								undefined,
								inspectionDetail.inspectionDetailSelectedUnit,
							),
						});
					} else {
						/* If conversion pair don't exists,i.e., eg:text then transform the boolean received to display */
						this.assetDetailsArray.push({
							assetDetailLabel: inspectionDetail.inspectionDetailDataName
								? formatWord(
										this.languageDictionaryService.getTranslations(
											inspectionDetail.inspectionDetailDataName,
										),
										"uppercase",
								  )
								: null,

							assetDetailContent: this.booleanTransformService.booleanTransform(
								undefined,
								inspectionDetail.inspectionDetailContent,
							),
						});
					}
				}
			});
		}
	}

	// build the open/pending table view for first child component
	public buildOpenPendingViewModel(openDefects: DefectList): OpenDefectTableViewModel[] {
		return openDefects.map(defect => {
			const openViewModel: OpenDefectTableViewModel = {
				assetId: defect.last.assetId,
				componentLabel: defect.last.componentName
					? this.languageDictionaryService.getTranslations(defect.last.componentName)
					: null,
				conditionLabel: defect.last.conditionName
					? this.languageDictionaryService.getTranslations(defect.last.conditionName)
					: null,
				configId: defect.configId,
				defectId: defect.defectId,
				// TODO: update defect status once we have translations
				defectStatus: defect.status ? defect.status.charAt(0).toUpperCase() + defect.status.slice(1) : null,
				firstNoted: newDate(defect.first.startTime),
				inspectionId: defect.last.inspectionId,
				inspectionType: defect.last.inspectionType,
				lastNoted: newDate(defect.last.startTime),
				photos: defect.first.defectMedia
					? this.photoViewModelService.buildPhotoViewModel(defect.first.defectMedia)
					: null,
				repairs: defect.repairs ? this.buildRepairViewModel(defect.repairs) : null,
				severity: defect.last.severity as number,
				zoneLabel: defect.last?.zoneName
					? this.languageDictionaryService.getTranslations(defect.last.zoneName)
					: null,
			};

			return openViewModel;
		});
	}

	// build closed/ignored table view for second child component
	public buildClosedDefectViewModel(closedDefects: DefectList): ClosedDefectViewModel[] {
		return closedDefects.map(defect => {
			const closedViewModel: ClosedDefectViewModel = {
				component: defect.last.componentName
					? this.languageDictionaryService.getTranslations(defect.last.componentName)
					: null,
				condition: defect.last.conditionName
					? this.languageDictionaryService.getTranslations(defect.last.conditionName)
					: null,
				inspection: defect.last.inspectionType, // TODO: we should translate inspectionType. Need the lang key for it.
				inspectionId: defect.last.inspectionId,
				mechanic: displayName(defect?.repairs?.[0]?.mechanicLastName, defect?.repairs?.[0]?.mechanicFirstName), // displayName  is a util function that takes in last name & first name and returns a formatted name
				notes: defect.repairs ? this.buildRepairNotes(defect.repairs[0]) : null,
				repairedDate: defect?.repairs?.[0] ? formatDate(newDate(defect.repairs[0].created), "P") : "—",
				severity: defect.last.severity,
				zone: defect.last?.zoneName
					? this.languageDictionaryService.getTranslations(defect.last.zoneName)
					: null,
			};

			return closedViewModel;
		});
	}

	public buildRepairViewModel(repairs: RepairResponse[]): RepairViewModel[] {
		return repairs.map((repair: RepairResponse) => {
			return {
				created: newDate(repair.created),
				comment: repair.comment,
			} as RepairViewModel;
		});
	}

	// if resolution (cleaned, adjusted, etc) anything but other, put resolution in notes column
	// if resolution is other, put the comment in notes instead
	// if no resolution, defect ignored, put "Repair not needed" in notes column
	public buildRepairNotes(repair: RepairResponse): string {
		if (repair.resolution) {
			return repair.resolution ===
				this.languageDictionaryService.getTranslations({
					languageKey: getZonarOtherUUID(),
					languageLabel: "Other",
				})
				? repair.comment
				: repair.resolution; // TODO: we need to update translations. We need to have a language key for each resolution
		} else {
			return translateAndFormat("repair not needed", "capitalize");
		}
	}

	// Once a closed defect event emitted by open/pending child, set closedDefects for input to closed/ignored child
	public updateClosedDefectTable(closedDefectEvent: ClosedDefects): void {
		this.closedDefects = closedDefectEvent;
	}

	public onOpenDefectsPaginate(query: URLSearchParams) {
		const queries = this.currentOpenDefectQuery;
		query.forEach((value, key) => {
			queries.set(key, value);
		});

		this.setCurrentOpenDefectQuery(queries);

		this.store.dispatch([
			new GetOpenAssetDefects(
				this.assetId,
				this.companyId,
				environment.environmentConstants.APP_ENDPOINT_EVIR,
				queries,
			),
		]);
	}

	private setCurrentOpenDefectQuery(queries: URLSearchParams) {
		queries.forEach((value, key) => {
			this.currentOpenDefectQuery.set(key, value);
		});
	}

	public getCurrentOpenDefectQuery() {
		return this.currentOpenDefectQuery;
	}

	public onClosedDefectsPaginate(query: URLSearchParams) {
		const queries = this.currentClosedDefectQuery;
		query.forEach((value, key) => {
			queries.set(key, value);
		});

		this.setCurrentClosedDefectQuery(queries);

		this.store.dispatch([
			new GetClosedAssetDefects(
				this.assetId,
				this.companyId,
				environment.environmentConstants.APP_ENDPOINT_EVIR,
				queries,
			),
		]);
	}

	private setCurrentClosedDefectQuery(queries: URLSearchParams) {
		queries.forEach((value, key) => {
			this.currentClosedDefectQuery.set(key, value);
		});
	}

	public getCurrentClosedDefectQuery() {
		return this.currentClosedDefectQuery;
	}

	// clear the asset after page leave so that state is cleared and doesn't keep the last asset on the screen if they want to look at a different asset
	ngOnDestroy() {
		this.store.dispatch(new ClearAsset());
		this.onDestroy$.next();
		this.onDestroy$.complete();
	}
}
